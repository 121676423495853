@import url("https://fonts.googleapis.com/css?family=Anton");
@import url("https://fonts.googleapis.com/css?family=Bubbler+One");

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overscroll-behavior-y: contain;
}

#root {
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.MuiBox-root::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.MuiBox-root {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  height: 100vh !important;
}

.swiper-slide img {
  width: 100%;
  height: auto;
  max-height: 70vh;
  object-fit: contain;
}

.swiper-slide .MuiTypography-root {
  margin: 4px 0;
}

/* Fix for iOS Safari viewport units */
@supports (-webkit-touch-callout: none) {
  .swiper-slide {
    height: -webkit-fill-available !important;
  }
}

/* Ensure proper height on mobile */
html,
body {
  height: -webkit-fill-available;
}

/* Reset default margins and ensure full height */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  height: -webkit-fill-available;
}

/* Fix for mobile browsers */
.swiper-slide {
  height: 100% !important;
  height: -webkit-fill-available !important;
  display: flex !important;
  align-items: center !important;
}

/* Ensure images don't overflow */
.swiper-slide img {
  max-width: 100%;
  max-height: 60vh;
  margin: auto;
  display: block;
}
